import React, { useContext } from 'react'
import { Link, graphql } from 'gatsby'
import RenderIf from '../../RenderIf'
import { ParagraphSchoolListingByWorldLanguageFieldsFragment } from '../../../types/generated'
import { LanguageContext } from '../../../context/LanguageContext'

export const ParagraphSchoolListingByWorldLanguageFields = graphql`
  fragment ParagraphSchoolListingByWorldLanguageFields on paragraph__school_listing_by_world_language {
    __typename
    id
    relationships {
      field_world_language {
        drupal_internal__tid
        name
        description {
          value
        }
        related_schools {
          title
          path {
            alias
          }
        }
      }
    }
  }
`

const ParagraphSchoolListingByWorldLanguage = ({
  relationships,
}: ParagraphSchoolListingByWorldLanguageFieldsFragment) => {
  const { t } = useContext(LanguageContext)
  const paragraphData = relationships?.field_world_language

  return (
    <div className="content-render prose">
      <h4>{paragraphData?.name}</h4>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: paragraphData?.description?.value ?? '' }} />
      <RenderIf condition={(paragraphData?.related_schools?.length ?? 0) > 0}>
        <p>{t('Schools')}</p>
        <ul>
          {paragraphData?.related_schools?.map((school: any) => (
            <li key={school.title}>
              <Link className="no-underline" to={school.path.alias}>
                {school.title}
              </Link>
            </li>
          ))}
        </ul>
      </RenderIf>
    </div>
  )
}

export default ParagraphSchoolListingByWorldLanguage
